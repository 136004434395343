import constant from './constants';
import { getToken, logout } from './dataService';

export var getAllTeamManagementAPI = (id, offset, limit, filter, active) => {
    return fetch(constant.API_URL + '/api/user-organization/list/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), offset: offset, limit: limit, filter: filter, active: active }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getActiveTeamManagementAPI = (id, page_no, limit, filter) => {
    return fetch(constant.API_URL + '/api/user-organization/active/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), page: page_no, limit: limit, filter: filter }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getArchiveTeamManagementAPI = (id, page_no, limit) => {
    return fetch(constant.API_URL + '/api/user-organization/archive/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), page: page_no, limit: 10 }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var toggleStatusTeamManagementAPI = (user_id, toggle_value) => {
    return fetch(constant.API_URL + '/api/user-organization/toggle', {
        method: "POST",
        body: JSON.stringify({ user_id, toggle_value }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var orgBillingAPI = (data) => {
    return fetch(constant.API_URL + '/api/organization-billing', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var UpdateOrgTeamManagementAPI = (id, data) => {
    return fetch(constant.API_URL + '/api/users/admin/' + id, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var bulkLocationUpload = (data, created_by) => {
    return fetch(constant.API_URL + '/api/masterData/bulkUploadOrganizationLocation', {
        method: "POST",
        body: JSON.stringify({ data: data, created_by: created_by }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var GetOrgTeamMemeberByIdAPI = (userId) => {
    return fetch(constant.API_URL + `/api/users/admin/${userId}`, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var GetRolesAPI = () => {
    return fetch(constant.API_URL + '/api/role/', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getRoleName = (id) => {
    return fetch(constant.API_URL + '/api/role/roleName/' + id, {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var AddTeamMemberAPI = (data) => {
    return fetch(constant.API_URL + '/api/users/admin', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var DeleteUser = (data) => {
    // console.log(data)
    return fetch(constant.API_URL + '/api/users/delete', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var DeleteTeamMember = (data) => {
    // console.log(data)
    return fetch(constant.API_URL + '/api/users/delete-team-member', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var gettrialOrgRegistrationAPI = (id) => {
    return fetch(constant.API_URL + '/api/organizations/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var gettrialOrgImageAPI = (id) => {
    return fetch(constant.API_URL + '/api/organizations/getImage/' + id, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
        },
    })
        .then((res) => res.json())
        .then((result) => {
            return result;
        })
        .catch((error) => {
            return error;
        })
}

export var getAllOrgById = (id) => {
    return fetch(constant.API_URL + '/api/organization-billing/org/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var deleteBillingAddress = (id, orgId) => {
    return fetch(constant.API_URL + '/api/organization-billing/' + id, {
        method: "DELETE",
        headers: { 
            'Content-Type': 'application/json', 
            Authorization: getToken(), 
            orgId: orgId 
        }
    })
    .then(res => res.json())
    .then(
        (result) => {
            console.log("Response from deleteBillingAddress:", result); // Log the result
            return result;
        },
        (error) => {
            console.error("Error in deleteBillingAddress:", error); // Log any errors
            return error;
        }
    )
}


export var getBillingById = (id) => {
    // console.log(id)
    return fetch(constant.API_URL + '/api/organization-billing/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var updatetrialOrgRegistrationAPI = (id, data) => {
    return fetch(constant.API_URL + '/api/organizations/' + id, {
        method: "POST",

        body: data,
        headers: { Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var updateOrgBillingAPI = (id, data) => {
    return fetch(constant.API_URL + '/api/organization-billing/update/' + id, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var getOrgInvoice = (id) => {
    return fetch(constant.API_URL + '/api/assesshub-invoice/org/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var deleteUserById = (id) => {
    return fetch(constant.API_URL + '/api/users/' + id, {
        method: "DELETE",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var requestCreditData = () => {
    return fetch(constant.API_URL + '/api/organization-licence/requestCreditData', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var requestCredit = (data) => {
    return fetch(constant.API_URL + '/api/organization-licence/requestCredit', {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var addCreditsByOrgId = (data) => {

    return fetch(constant.API_URL + '/api/organization-licence/addCredit', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var addCreditsPospaidByOrgId = (data) => {
    return fetch(constant.API_URL + '/api/organization-licence/addCreditpostpaid', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var addManualKeyByOrgId = (data) => {
    return fetch(constant.API_URL + '/api/organization-licence/addCreditManual', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}


export var registrationFormLayout = (data) => {
    return fetch(constant.API_URL + '/api/organization-registrationformlayout', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getRegistrationFormLayout = (id) => {
    return fetch(constant.API_URL + '/api/organization-registrationformlayout/' + id, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var getTheme = () => {
    return fetch(constant.API_URL + '/api/organization-registrationformlayout/get-theme/org', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var changeTheme = (data) => {
    return fetch(constant.API_URL + '/api/organization-registrationformlayout/theme', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getCountryList = () => {
    return fetch(constant.API_URL + '/api/country/', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getStateList = (data) => {
    return fetch(constant.API_URL + '/api/state/stateData', {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getTimeZone = (data) => {
    // console.log(typeof data)
    return fetch(constant.API_URL + '/api/country/timeZone', {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getCityList = (data) => {
    return fetch(constant.API_URL + '/api/city/cityList', {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: { 'Content-Type': 'application/json' }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var masterDataLocationByOrgId = (data) => {
    return fetch(constant.API_URL + '/api/profile/masterDataByOrgId', {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var updateMasterDataStatus = (id, active) => {
    return fetch(constant.API_URL + '/api/masterData/updateStatus', {
        method: "POST",
        body: JSON.stringify({ id, active }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var deleteLocationAPI = (id) => {
    return fetch(constant.API_URL + '/api/masterData/delete-organizationmasterData', {
        method: "POST",
        body: JSON.stringify({ id }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export let getLocationExcel = (data) => {
    return (
        fetch(constant.API_URL + "/api/masterData/download_excel", {
            method: "POST",
            body: JSON.stringify({ data: data }),
            headers: {
                "Content-Type": "application/json",
                Authorization: getToken(),
            },
        })
            // .then((res) => res.json())
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return error;
            })
    );
};

export var masterDataAPI = (data) => {
    return fetch(constant.API_URL + '/api/masterData/organizationmasterData', {
        method: "POST",
        body: JSON.stringify({ data: data }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var deleteMasterDataAPI = (id) => {
    return fetch(constant.API_URL + '/api/masterData/delete-organizationmasterData', {
        method: "POST",
        body: JSON.stringify({ id: id }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var AddOrgSamlDetailsAPI = (data) => {
    return fetch(constant.API_URL + '/api/org-saml-details', {
        method: "POST",
        body: data,
        headers: { Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}

export var getOrgSamlDetailsAPI = () => {
    return fetch(constant.API_URL + '/api/org-saml-details/orgid', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}
export var updateOrgSamlDetailsAPI = (data) => {
    return fetch(constant.API_URL + '/api/org-saml-details', {
        method: "PUT",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}


export var getCurrencyValue = () => {
    return fetch(constant.API_URL + '/api/country/countryValue', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )

}

export var getOrgData = () => {
    return fetch(constant.API_URL + '/api/organizations/orgData', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )

}

export var getUnitPrice = (data) => {
    return fetch(constant.API_URL + '/api/profile/unitprice', {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )

}

export var getPendingRequest = (page, filterValue) => {
    return fetch(constant.API_URL + '/api/organization-licence/pendingRequest', {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), page: page, filterValue: filterValue, limit: 10 }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var getCompletedRequest = (page, filterValue) => {
    return fetch(constant.API_URL + '/api/organization-licence/completedRequest', {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), page: page, filterValue: filterValue, limit: 10 }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var invoiceNo = () => {
    return fetch(constant.API_URL + '/api/profile/invoiceno', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var manualinvoiceno = () => {
    return fetch(constant.API_URL + '/api/profile/manualinvoiceno', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var postpaidinvoiceno = () => {
    return fetch(constant.API_URL + '/api/profile/postpaidinvoiceno', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var billingID = (oid) => {
    return fetch(constant.API_URL + '/api/profile/billingid', {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), oid: oid }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var getTotalCreditsData = (offset, limit, registrationKeyFilter) => {
    return fetch(constant.API_URL + '/api/profile/total-credits', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), offset, limit, registrationKeyFilter }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var getUsedCreditsData = (offset, limit, registrationKeyFilter, nameEmailIdFilter) => {
    return fetch(constant.API_URL + '/api/profile/used-credits', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), offset, limit, registrationKeyFilter, nameEmailIdFilter }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var getUnusedCreditsData = (offset, limit, registrationKeyFilter) => {
    return fetch(constant.API_URL + '/api/profile/unused-credits', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), offset, limit, registrationKeyFilter }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var releaseCredits = (action) => {
    return fetch(constant.API_URL + '/api/profile/release-credits', {
        method: "POST",
        // body: JSON.stringify({ creditsCount }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken(), action }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var bulkDeleteCredits = (creditsCount) => {
    return fetch(constant.API_URL + '/api/profile/bulk-delete-unused-credits', {
        method: "POST",
        body: JSON.stringify({ creditsCount }),
        headers: { 'Content-Type': 'application/json', Authorization: getToken() }
    })
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }

        )
}

export var getCreditsExcel = (selectedTab, registrationKeyFilter, nameEmailIdFilter) => {
    return fetch(constant.API_URL + `/api/profile/${selectedTab}-credits-excel`, {
        method: "GET",
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: getToken(),
            selectedTab: selectedTab,
            registrationKeyFilter: registrationKeyFilter,
            nameEmailIdFilter: nameEmailIdFilter
        }
    })
        // .then(res => res.json())
        .then(
            (result) => {
                return result;
            },
            (error) => {
                return error;
            }
        )
}