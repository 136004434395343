import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import Aux from "../../../../hoc/_Aux";
import logo from "../../../../assets/images/AH_logo.png";
import AH_logo from "../../../../assets/images/AH-logo.png";
import AH_name from "../../../../assets/images/AH-name.png";
import { getTheme } from "../../../../service/profileApiService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { stickyHeaderStyles } from "../../../../utils/constants/styleConstants";
import moduleNames from "./moduleNames";
import { logout } from '../../../../service/dataService'
import { useNavigate } from "react-router-dom";

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

const SideBar = ({ props }) => {
  const navigate = useNavigate()

  const { collapsed, collapseSidebar } = useProSidebar();
  const [hoveringOnModule, setHoveringOnModule] = useState();
  const [theme, setTheme] = useState("primary");
  const [isLoading, setIsLoading] = useState(true);
  const [currentRoleId, setCurrentRoleId] = useState(JSON.parse(localStorage.getItem("roleId")));
  const [orgId, setOrgId] = useState(JSON.parse(localStorage.getItem("orgId")));

  useEffect(() => {
    getTheme().then((res) => {
      let themes = res?.result?.find((i) => i.field_name == "THEME")?.field_details;
      if (themes) {
        setTheme(themes);
      }
      setIsLoading(false);
      // if (res?.result?.find((i) => i.field_name == 'LOGO')?.field_details) {
      // setMainLogo(newlogo)
      // }
    });
  }, []);

  return (
    <Aux>
      {isLoading ? (
        <Skeleton width="80px" height="100%" />
      ) : (
        <>
          <Button
            className={localStorage.getItem("isSideBarCollapsed") == "true" ? `d-inline d-md-none` : "d-none"}
            style={{
              color: "white",
              position: "absolute",
              top: 5,
              right: "-48px",
              zIndex: 101,
              backgroundColor: "#43CBDE",
              border: "none",
              boxShadow: "3px 0 8px 0 #D3D3D3",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "50%",
              fontSize: "1rem",
            }}
            onClick={() => {
              localStorage.setItem("isSideBarCollapsed", !collapsed);
              collapseSidebar(!collapsed);
            }}
          >
            <i class="fas fa-chevron-right" aria-hidden="true"></i>
          </Button>
          <Sidebar
            defaultCollapsed={localStorage.getItem("isSideBarCollapsed") == "true"}
            width="210px"
            collapsedWidth={window.innerWidth >= 768 ? "80px" : "0px"}
            style={{
              ...stickyHeaderStyles,
              borderRight: "1px solid #E8E8E8",
              height: "100vh",
              boxShadow: "3px 0 8px 0 #D3D3D3",
              zIndex: 100,
            }}
          >
            <Menu>
              <MenuItem
                className="py-1 text-right"
                style={{
                  backgroundColor: "white",
                }}
              >
                <Button
                  className={hoveringOnModule == 0 ? `p-0 bg-${theme}` : "p-0"}
                  onClick={() => {
                    localStorage.setItem("isSideBarCollapsed", !collapsed);
                    collapseSidebar(!collapsed);
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(0);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    width: "40px",
                    height: "40px",
                    border: "none",
                    fontSize: "20px",
                    color: hoveringOnModule == 0 ? "white" : "black",
                    backgroundColor: hoveringOnModule == 0 ? "" : "white",
                  }}
                >
                  <i class="fa fa-bars" aria-hidden="true"></i>
                </Button>
              </MenuItem>
              <MenuItem style={{ backgroundColor: "white", pointerEvents: "none" }}>
                {/* <img id="main-logo" src={logo} alt="" style={{ width: "170px", height: "auto" }} className="img img-responsive" /> */}
                <img
                  id="main-name"
                  src={AH_name}
                  alt=""
                  style={{ width: "auto", height: "56px" }}
                  className="img img-responsive"
                />
                <div
                  style={{
                    backgroundColor: "white",
                    display: "inline",
                    position: "absolute",
                    right: "16px",
                    top: "10%",
                  }}
                >
                  <img
                    id="main-logo"
                    src={AH_logo}
                    alt=""
                    style={{ width: "auto", height: "43px" }}
                    className="img img-responsive float-right"
                  />
                </div>
              </MenuItem>
              <OverlayTrigger
                rootClose
                placement="bottom"
                overlay={<Tooltip>{localStorage.getItem("userName").replace(/['"]/g, "")}</Tooltip>}
              >
                <MenuItem
                  className="pt-2 mt-2"
                  style={{
                    color: "black",
                    backgroundColor: "transparent",
                    cursor: "default",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i
                      class="fas fa-user-circle"
                      style={{ fontSize: "24px", cursor: "default", color: "#A9A9A9" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="d-inline mb-3" style={{ fontSize: "0.9rem" }}>
                    {localStorage.getItem("userName").replace(/['"]/g, "")}
                  </div>
                </MenuItem>
              </OverlayTrigger>
              <hr />
              {currentRoleId === 1 || localStorage.getItem("userName").replace(/['"]/g, "") == "admin@nasscomitites.com" ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[0] ||
                      !moduleNames.includes(localStorage.getItem("selectedModule")) ||
                      hoveringOnModule == moduleNames[0]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[0]);
                    navigate("/s-admin/dashboard");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[0]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[0] ||
                        !moduleNames.includes(localStorage.getItem("selectedModule")) ||
                        hoveringOnModule == moduleNames[0]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-pie-chart" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Dashboard</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {/* {currentRoleId === 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[1] || hoveringOnModule == moduleNames[1]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[1]);
                    navigate("/organisation");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[1]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[1] || hoveringOnModule == moduleNames[1]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-university" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Organization</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )} */}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[2] || hoveringOnModule == moduleNames[2]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[2]);
                    navigate("/group");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[2]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[2] || hoveringOnModule == moduleNames[2]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-user-plus" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Online Tests</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[3] || hoveringOnModule == moduleNames[3]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[3]);
                    navigate("/fb/configure");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[3]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[3] || hoveringOnModule == moduleNames[3]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-comments" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Feedback Survey</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[4] || hoveringOnModule == moduleNames[4]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[4]);
                    navigate("/es/questions/add");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[4]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[4] || hoveringOnModule == moduleNames[4]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-vcard" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Engagement Survey</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[5] || hoveringOnModule == moduleNames[5]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[5]);
                    navigate("/adc/create");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[5]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[5] || hoveringOnModule == moduleNames[5]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-pencil-square-o" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Assessment Center</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {orgId === 506 ||
                (rolePermission[17]?.create &&
                  rolePermission[17]?.read &&
                  rolePermission[17]?.update &&
                  rolePermission[17]?.delete) ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[6] || hoveringOnModule == moduleNames[6]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[6]);
                    navigate("/be/project-insights");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[6]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[6] || hoveringOnModule == moduleNames[6]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2 pr-3">
                    <i class="fas fa-users" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Batch Evaluation</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[7] || hoveringOnModule == moduleNames[7]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[7]);
                    navigate("/adc/create");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[7]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[7] || hoveringOnModule == moduleNames[7]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2" style={{ paddingRight: "20px" }}>
                    <i class="fa fa-android" style={{ fontSize: "27px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Bot</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[8] || hoveringOnModule == moduleNames[8]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[8]);
                    navigate("/translate");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[8]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[8] || hoveringOnModule == moduleNames[8]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2" style={{ paddingRight: "20px" }}>
                    <i class="fa fa-language" style={{ fontSize: "27px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Translation</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[9] || hoveringOnModule == moduleNames[9]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[9]);
                    navigate("/faq");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[9]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[9] || hoveringOnModule == moduleNames[9]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2" style={{ paddingRight: "20px" }}>
                    <i class="fa fa-comment-dots" style={{ fontSize: "27px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>KnowledgeBase-FAQ</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              {currentRoleId !== 1 ? (
                <MenuItem
                  className={
                    localStorage.getItem("selectedModule") == moduleNames[10] || hoveringOnModule == moduleNames[10]
                      ? `py-1 my-1 bg-${theme}`
                      : "py-1 my-1"
                  }
                  onClick={() => {
                    localStorage.setItem("selectedModule", moduleNames[10]);
                    navigate("/custom-form");
                    if (window.innerWidth < 992) {
                      window.location.reload();
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveringOnModule(moduleNames[10]);
                  }}
                  onMouseLeave={() => {
                    setHoveringOnModule();
                  }}
                  style={{
                    color:
                      localStorage.getItem("selectedModule") == moduleNames[10] || hoveringOnModule == moduleNames[10]
                        ? "white"
                        : "black",
                    backgroundColor: "transparent",
                  }}
                >
                  <div className="d-inline-block pl-2" style={{ paddingRight: "20px" }}>
                    <i class="fa fa-comment-dots" style={{ fontSize: "27px" }} aria-hidden="true"></i>
                  </div>
                  <span style={{ fontSize: "0.8rem" }}>
                    <b>Create Forms</b>
                  </span>
                </MenuItem>
              ) : (
                ""
              )}
              <MenuItem
                onClick={logout}
                style={{
                  position: "absolute", // Fix the position
                  bottom: "-142px", // Adjust bottom margin
                  left: "0", // Align it to the left
                  width: "100%", // Make it full width
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <div className="d-inline-block pl-2 pr-3">
                  <i class="fas fa-sign-out-alt" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                </div>
                <span style={{ fontSize: "0.8rem" }}>
                  <b>LOGOUT</b>
                </span>
              </MenuItem>

            </Menu>
          </Sidebar>
        </>
      )}
    </Aux>
  );
};

export default SideBar;
