import { modules } from "../NewSidebar/sidebarModules"

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"))

export let profileMenu = [
    {
        name: "PF - My Profile",
        label: "My Profile",
        url: "/my-profile",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[27]?.id)?.hidden,
    },
    {
        name: "PF - My Company",
        label: "My Company",
        url: "/my-company",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[28]?.id)?.hidden,
    },
    {
        name: "PF - Team Management",
        label: "Team Management",
        url: "/team-management",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[29]?.id)?.hidden,
    },
    {
        name: "PF - Plan & Billing",
        label: "Plan & Billing",
        url: "/plan-and-billing",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[30]?.id)?.hidden,
    },
    {
        name: "PF - Integration",
        label: "Integration",
        url: "/integration",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[31]?.id)?.hidden,
    },
    {
        name: "PF - Registration",
        label: "Registration",
        url: "/registration",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[32]?.id)?.hidden,
    },
    {
        name: "PF - Location",
        label: "Location",
        url: "/location",
        hidden: rolePermission?.find(item => item?.module_id == modules?.[33]?.id)?.hidden,
    },
]

export let themeNames = ["primary", "danger", "secondary", "info", "success"]