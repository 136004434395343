import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import Aux from "../../../../hoc/_Aux";
import logo from "../../../../assets/images/assesshub logo green dot.svg";   
import { getTheme , gettrialOrgImageAPI } from "../../../../service/profileApiService";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { stickyHeaderStyles } from "../../../../utils/constants/styleConstants";
import { sidebarModules } from "./sidebarModules";

import { themeNames } from "../NewNavbar/navbarConstants";
import "./styles.css"
import { useNavigate } from 'react-router-dom';
const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));


const NewSideBar = ({ props }) => {
    const { collapsed, collapseSidebar } = useProSidebar();
    const [hoveringOnModule, setHoveringOnModule] = useState();
    const [theme, setTheme] = useState(localStorage.getItem("themeColor") || "primary");
    const [isLoading, setIsLoading] = useState(false);
    const [currentRoleId, setCurrentRoleId] = useState(JSON.parse(localStorage.getItem("roleId")));
    const [orgId, setOrgId] = useState(JSON.parse(localStorage.getItem("orgId")));
    const [logoLink, setLogoLink] = useState(() => {
        const storedLogo = JSON.parse(localStorage.getItem("logo"));
        return storedLogo !== null ? storedLogo : logo;
      });

    const menuItemStyle = {
        paddingTop: "10px",
        paddingBottom: "10px",
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        lineHeight: "45px",
    }

    const subMenuItemStyle = {
        height: "35px",
        paddingTop: "2px",
        paddingBottom: "2px",
        marginTop: "2px",
        marginBottom: "2px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        lineHeight: "30px",
    }

    const fontColor = "#484848"

    useEffect(() => {
        getTheme().then((res) => {
          let themes = res?.result?.find(
            (i) => i.field_name == "THEME"
          )?.field_details;
          if (themes) {
            setTheme(themes);
            localStorage.setItem("themeColor", themes);
          }
          setIsLoading(false);
        });
      }, []);
    
      useEffect(() => {
        const isCollapsed = localStorage.getItem("isSideBarCollapsed") === "true";
        collapseSidebar(isCollapsed);
      }, [collapseSidebar]);
    
      setInterval(() => {
        if (
          themeNames.includes(localStorage.getItem("themeColor")) &&
          localStorage.getItem("themeColor") != theme
        ) {
          setTheme(localStorage.getItem("themeColor"));
        }
      }, 500);

    const navigate = useNavigate();

    return (
        <Aux>
            {isLoading ? (
                <Skeleton width="80px" height="100%" />
            ) : (
                <>
                    <Sidebar
                        defaultCollapsed={localStorage.getItem("isSideBarCollapsed") == "true"}
                        width="280px"
                        collapsedWidth={window.innerWidth >= 768 ? "100px" : "0px"}
                        style={{
                            ...stickyHeaderStyles,
                            top: 0,
                            borderRight: "1px solid #E8E8E8",
                            height: "100vh",
                            boxShadow: "3px 0 8px 0 #D3D3D3",
                            zIndex: 100,
                        }}
                    >
                        <Menu>
                            <MenuItem
                                style={{
                                    ...menuItemStyle,
                                    backgroundColor: "white",
                                    color: fontColor,
                                    cursor: "default",
                                }}
                            >
                                <div
                                    className={hoveringOnModule == 0 ? `sidebar-div bg-${theme}` : "sidebar-div"}
                                    style={{
                                        color: hoveringOnModule == 0 ? "white" : fontColor,
                                        backgroundColor: hoveringOnModule == 0 ? "" : "white",
                                        width: "60px",
                                        display: "inline-block",
                                    }}
                                    // onMouseEnter={() => {
                                    //     setHoveringOnModule(0);
                                    // }}
                                    // onMouseLeave={() => {
                                    //     setHoveringOnModule();
                                    // }}
                                    onClick={() => {
                                        localStorage.setItem("isSideBarCollapsed", !collapsed);
                                        collapseSidebar(!collapsed);
                                    }}
                                >
                                    <i
                                        class="fa fa-bars sidebar-icon"
                                        aria-hidden="true"
                                    ></i>
                                    <span className="sidebar-text"></span>
                                </div>
                                <img
                                    id="main-logo" src={logoLink} alt=""
                                    style={{
                                        width: "120px",
                                        height: "30px", 
                                        display: "inline",
                                        marginBottom: "10px",
                                        transition: "all 0.5s ease",
                                        visibility: localStorage.getItem("isSideBarCollapsed") == "true" ? "hidden" : "visible",
                                        opacity: localStorage.getItem("isSideBarCollapsed") == "true" ? "0" : "1"
                                    }}
                                    className="img img-responsive" />
                            </MenuItem>
                            <hr className="mt-0" />
                            <div style={{ overflowY: "auto", overflowX: "clip", paddingBottom: "40px" }}>
                                {sidebarModules().map((moduleData, moduleIndex) => {
                                    
                                    if (!moduleData?.hidden
                                        && ((moduleIndex == 0 && (currentRoleId === 1 || localStorage.getItem("userName")?.replace(/['"]/g, "") == "admin@nasscomitites.com"))
                                            || (moduleIndex == 1 && (currentRoleId === 1))
                                            || (moduleIndex == 6 && (orgId === 506 || ( rolePermission!==null && rolePermission[17]?.create && rolePermission[17]?.read && rolePermission[17]?.update && rolePermission[17]?.delete)))
                                            || (![0, 1, 6].includes(moduleIndex) && (currentRoleId !== 1)))
                                    ) {
                                        if (moduleData?.subMenu && moduleData?.subMenu?.length != 0) {
                                            return (
                                                <SubMenu
                                                    defaultOpen={localStorage.getItem("selectedModule") == moduleData.name}
                                                    style={localStorage.getItem("isSideBarCollapsed") == "true" ? {
                                                        ...menuItemStyle,
                                                        color: "white",
                                                        backgroundColor: "white",
                                                    } : {
                                                        ...menuItemStyle,
                                                        color: "white",
                                                        backgroundColor: "white",
                                                        paddingRight: "15px"
                                                    }}
                                                    label={
                                                        <div
                                                            className={localStorage.getItem("selectedModule") == moduleData.name || (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) || hoveringOnModule == moduleData.name
                                                                ? `sidebar-div bg-${theme}` : "sidebar-div"
                                                            }
                                                            style={{
                                                                color: localStorage.getItem("selectedModule") == moduleData.name || (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) || hoveringOnModule == moduleData.name
                                                                    ? "white" : fontColor,
                                                                backgroundColor: localStorage.getItem("selectedModule") == moduleData.name || (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) || hoveringOnModule == moduleData.name
                                                                    ? "" : "white",
                                                            }}
                                                            // onClick={() => {
                                                            //     localStorage.setItem("selectedModule", moduleData.name);
                                                            //     // navigate(moduleData.url);
                                                            //     if (window.innerWidth < 992) {
                                                            //         window.location.reload();
                                                            //     }
                                                            // }}
                                                            onMouseEnter={() => {
                                                                setHoveringOnModule(moduleData.name);
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHoveringOnModule();
                                                            }}
                                                        >
                                                            <i
                                                                class={`${moduleData.icon} sidebar-icon`}
                                                                aria-hidden="true"
                                                            ></i>
                                                            <span className="sidebar-text">{moduleData.label}</span>
                                                            <i
                                                                class={localStorage.getItem("isSideBarCollapsed") == "true" ? "fa fa-angle-right sidebar-icon" : "fa fa-angle-down sidebar-icon"}
                                                                style={{ position: "absolute", right: localStorage.getItem("isSideBarCollapsed") == "true" ? -2 : 5 }}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    }
                                                >
                                                    {moduleData.subMenu.filter(item => item.hidden != true).map((subModuleData, subModuleIndex) => {
                                                        return (
                                                            <MenuItem style={localStorage.getItem("isSideBarCollapsed") == "true" ? subMenuItemStyle : { ...subMenuItemStyle, marginLeft: "25px" }}>
                                                                <div id={subModuleData.id}
                                                                    className={localStorage.getItem("selectedSubModule") == subModuleData.name || hoveringOnModule == subModuleData.name
                                                              
                                                                        ? `sidebar-div bg-${theme}` : "sidebar-div"
                                                                    }
                                                                    style={{
                                                                        color: localStorage.getItem("selectedSubModule") == subModuleData.name || hoveringOnModule == subModuleData.name
                                                                            ? "white" : fontColor,
                                                                        backgroundColor: localStorage.getItem("selectedSubModule") == subModuleData.name || hoveringOnModule == subModuleData.name
                                                                            ? "" : "white",
                                                                        opacity: localStorage.getItem("selectedSubModule") == subModuleData.name || hoveringOnModule == subModuleData.name
                                                                            ? "0.65" : "1",
                                                                    }}
                                                                    onClick={() => {
                                                                        localStorage.setItem("selectedModule", moduleData.name);
                                                                        localStorage.setItem("selectedSubModule", subModuleData.name);
                                                                        // navigate(subModuleData.url);
                                                                        navigate(subModuleData.url);
                                                                        // if (window.innerWidth < 992) {
                                                                        //     window.location.reload();
                                                                        // }
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setHoveringOnModule(subModuleData.name);
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setHoveringOnModule();
                                                                    }}
                                                                >
                                                                    <span className="sidebar-text ml-3">{subModuleData.label}</span>
                                                                </div>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </SubMenu>
                                            )
                                        }
                                        else {
                                            return (
                                                <MenuItem style={menuItemStyle}>
                                                    <div
                                                        className={localStorage.getItem("selectedModule") == moduleData.name ||
                                                            (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) ||
                                                            hoveringOnModule == moduleData.name
                                                            ? `sidebar-div bg-${theme}` : "sidebar-div"
                                                        }
                                                        style={{
                                                            color: localStorage.getItem("selectedModule") == moduleData.name ||
                                                                (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) ||
                                                                hoveringOnModule == moduleData.name
                                                                ? "white" : fontColor,
                                                            backgroundColor: localStorage.getItem("selectedModule") == moduleData.name ||
                                                                (moduleIndex == 0 && !sidebarModules().map(item => item.name).includes(localStorage.getItem("selectedModule"))) ||
                                                                hoveringOnModule == moduleData.name
                                                                ? "" : "white",
                                                        }}
                                                        onClick={() => {
                                                            localStorage.setItem("selectedModule", moduleData.name);
                                                            localStorage.setItem("selectedSubModule", undefined);
                                                            navigate(moduleData.url);
                                                            // if (window.innerWidth < 992) {
                                                            //     window.location.reload();
                                                            // }
                                                        }}
                                                        onMouseEnter={() => {
                                                            setHoveringOnModule(moduleData.name);
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHoveringOnModule();
                                                        }}
                                                    >
                                                        <i
                                                            class={`${moduleData.icon} sidebar-icon`}
                                                            aria-hidden="true"
                                                        ></i>
                                                        <span className="sidebar-text">{moduleData.label}</span>
                                                    </div>
                                                </MenuItem>
                                            )
                                        }
                                    }
                                })}
                            </div>
                            {/* {currentRoleId !== 1 ? (
                                <MenuItem
                                    onClick={logout}
                                    style={{
                                        position: "absolute", // Fix the position
                                        bottom: "-142px", // Adjust bottom margin
                                        left: "0", // Align it to the left
                                        width: "100%", // Make it full width
                                        fontFamily: "Arial, sans-serif",
                                    }}
                                >
                                    <div className="d-inline-block pl-2 pr-3">
                                        <i class="fas fa-sign-out-alt" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                                    </div>
                                    <span style={{ fontSize: "0.8rem" }}>
                                        <b>LOGOUT</b>
                                    </span>
                                </MenuItem>
                            ) : (
                                ""
                            )} */}
                        </Menu>
                    </Sidebar>
                </>
            )
            }
        </Aux >
    );
};

export default NewSideBar;
