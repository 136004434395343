export let buttonStyles = {
    width: "130px",
    padding: "10px",
}

export let buttonStylesWithMarginToLeft = {
    marginLeft: "15px",
    width: "130px",
    padding: "10px",
}

export let buttonStylesForMobile = {
    width: "45px",
    height: "45px",
    fontSize: "1.5rem",
}

export let buttonStylesWithMarginToLeftForMobile = {
    marginLeft: "10px",
    width: "45px",
    height: "45px",
    fontSize: "1.5rem",
}

export let buttonOutlinedStyles = {
    width: "130px",
    padding: "10px",
    background: "#fff",
    color: "#008000",
    border: "1px solid #008000",
}

export let buttonOutlinedStylesWithMarginToLeft = {
    marginLeft: "15px",
    width: "130px",
    padding: "10px",
    background: "#fff",
    color: "#008000",
    border: "1px solid #008000",
}

export let buttonOutlinedStylesForMobile = {
    width: "45px",
    height: "45px",
    fontSize: "1.5rem",
    background: "#fff",
    color: "#008000",
    border: "1px solid #008000",
}

export let buttonOutlinedStylesWithMarginToLeftForMobile = {
    marginLeft: "10px",
    width: "45px",
    height: "45px",
    fontSize: "1.5rem",
    background: "#fff",
    color: "#008000",
    border: "1px solid #008000",
}

export let excelButtonStyles = {
    color: "#246586",
    border: "1px solid #246586",
    cursor: "pointer",
    background: "#fff",
    whiteSpace: "nowrap",
    width: "150px",
}

export let excelButtonStylesWithMarginLeft = {
    color: "#246586",
    border: "1px solid #246586",
    cursor: "pointer",
    background: "#fff",
    whiteSpace: "nowrap",
    width: "150px",
    marginLeft: "15px",
}

export let excelButtonStylesForMobile = {
    color: "#246586",
    border: "1px solid #246586",
    cursor: "pointer",
    background: "#fff",
    whiteSpace: "nowrap",
    width: "45px",
    height: "45px",
    fontSize: "1.4rem",
    // backgroundImage: 'url(../../assets/images/excelicon.png)',
    // backgroundPosition: "50% 50%",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "20px",
}

export let excelButtonStylesWithMarginLeftForMobile = {
    color: "#246586",
    border: "1px solid #246586",
    cursor: "pointer",
    background: "#fff",
    whiteSpace: "nowrap",
    width: "45px",
    height: "45px",
    fontSize: "1.4rem",
    // backgroundImage: 'url(../../assets/images/excelicon.png)',
    // backgroundPosition: "50% 50%",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "20px",
    marginLeft: "15px",
}

export let backButtonStyles = {
    width: "40px",
    height: "40px",
    fontSize: "1.2rem",
}

export let floatingLabelStyles = {                 // give style "postion: relative" to parent element
    position: "absolute",
    top: "-10px",
    left: "15px",
    backgroundColor: "white",
    fontSize: "0.8rem",
    color: "#528aa3",
    maxWidth: "90%",
}

export let floatingLabelStylesForDropdown = {      // give style "postion: relative" to parent element
    position: "absolute",
    top: "-10px",
    left: "15px",
    backgroundColor: "white",
    fontSize: "0.8rem",
    color: "#528aa3",
    maxWidth: "90%",
    zIndex: 10,
}

export let inputStyles = {
    border: "1px solid #d3d3d3",
    borderRadius: "3px",
    padding: "6px",
}

export let dropdownStyles = {
    paddingTop: "6px",
    paddingBottom: "6px",
}

export let dropdownMenuStyles = {
    zIndex: 20,
}

export let textEllipsisStyles = {                  // give style "overflow: hidden" to parent element
    textOverflow: "ellipsis",               // give style "tableLayout: fixed" to parent element if parent is a table
    whiteSpace: "nowrap",
    overflow: "hidden",
}

export let labelStyles = {
    color: "#528aa3",
}

export let textareaStyles = {
    border: "1px solid #D3D3D3",
    borderRadius: "3px",
    resize: "none"
}

export let errorMessageStyles = {
    color: "red",
    fontSize:"0.9em"
}

export let requiredFieldAsteriskStyles = {
    color: "red",
}

export let stickyHeaderStyles = {
    position: "sticky",
    top: "61px",
    backgroundColor: "white",
    zIndex: 10,
}

export let activeArchivedButtonStyles = {
    minWidth: "150px"
}

export let activeArchivedButtonStylesForMobile = {
    width: ""
}

export let joditEditorConfig = {
    buttons: ["bold", "italic", "underline", "|", "ul", "ol", "|", "center", "left", "right", "justify", "|", "link"],
    // uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
}

export let disabledStyles = {
    pointerEvents: "none",
    opacity: "0.4"
}

export let dashboardCardStyles = {
    boxShadow: "5px 5px 7px 0 #D3D3D3",
    border: "1px solid #D3D3D3",
    borderRadius: "10px",
    marginBottom: "30px",
}

export let dashboardCountCardStyles = {
    ...dashboardCardStyles,
    color: "white",
    background: "linear-gradient(to top, rgb(62, 5, 116) -5.2%, rgb(41, 14, 151) -5.2%, rgb(216, 68, 148) 103.3%)",
    cursor: "pointer",
}

export let previousButtonStyles = {
    width: "40px",
    height: "40px",
    fontSize: "1.2rem",
    backgroundColor: "#F2F2F2",
    verticalAlign: "bottom",
}

export let nextButtonStyles = {
    ...previousButtonStyles,
    marginLeft: "15px",
}