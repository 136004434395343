import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';

import './assets/scss/style.scss';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { fetchInterceptor } from './FetchInterceptor';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleLoginConstants } from './utils/constants/googleLoginConstants';

const store = createStore(reducer);
// document.documentElement.style.setProperty('--primary-color', "#000000");

fetchInterceptor()

const app = (
    <Provider store={store}>
            <ProSidebarProvider>

            <HashRouter>
            <GoogleOAuthProvider clientId={googleLoginConstants.client_id}>
                    <App />
                </GoogleOAuthProvider>
            </HashRouter>
            </ProSidebarProvider>

    </Provider>
);

//ReactDOM.render(app, document.getElementById('root'));
const root = createRoot(document.getElementById('root'));

root.render(app);
 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
