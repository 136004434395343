export default [
    "S-Admin Dashboard",
    "Organization",
    "Online Tests",
    "Feedback Survey",
    "Engagement Survey",
    "Assessment Centre",
    "Batch Evaluation",
    "Bot",
    "Translation",
    "KnowledgeBase-FAQ",
    "Create-Forms",
    "Logout"
]