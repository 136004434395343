import moduleNames from "./App/layout/AdminLayout/SideBar/moduleNames";
import { modules } from "./App/layout/AdminLayout/NewSidebar/sidebarModules";

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"));

const data =
  localStorage.getItem("selectedModule") == moduleNames[0] ||
    !moduleNames.includes(localStorage.getItem("selectedModule"))
    ? {
      items: [
        {
          id: "navigation",
          title: "Dashboard",
          type: "group",
          icon: "icon-navigation",
          children: [
            {
              id: "ah-s-admin-dashboard",
              title: "Dashboard",
              type: "item",
              // icon: 'feather icon-user-check',
              url: "/s-admin/dashboard",
            },
          ],
        },
      ],
    }
    : localStorage.getItem("selectedModule") == moduleNames[2]
      ? {
        items: [
          {
            id: "navigation",
            title: "Dashboard",
            type: "group",
            icon: "icon-navigation",
            children: [
              // {
              //   id: "ah-position",
              //   title: "My Positions",
              //   type: "item",
              //   // icon: 'feather icon-user-check',
              //   url: "/position",
              //   status:
              //     rolePermission?.length > 0
              //       ? rolePermission[0]?.create ||
              //       rolePermission[0]?.read ||
              //       rolePermission[0]?.update ||
              //       rolePermission[0]?.delete
              //       : true,
              // },
              {
                id: "ah-group",
                title: "My Positions",
                type: "item",
                // icon: 'feather icon-user-check',
                url: "/group",
                status: rolePermission?.find(item => item.module_id == modules[0].id)?.read,
              },
              {
                id: "ah-candidates",
                title: "My Candidates",
                type: "item",
                // icon: 'feather icon-users',
                url: "/candidates",
                status: rolePermission?.find(item => item.module_id == modules[2].id)?.read,
              },
              {
                id: "ah-skillarea",
                title: "My Skill Areas",
                type: "item",
                // icon: 'feather icon-user-check',
                url: "/skill-area",
                onClick: "",
                status: rolePermission?.find(item => item.module_id == modules[3].id)?.read || rolePermission?.find(item => item.module_id == modules[4].id)?.read,
              },
              {
                id: "ah-tests",
                title: "My Tests",
                type: "item",
                // icon: 'feather icon-file-text',
                url: "/test",
                status: rolePermission?.find(item => item.module_id == modules[1].id)?.read,
              },
              {
                id: "ah-per-rpt",
                title: "Personalized Report",
                type: "item",
                // icon: 'feather icon-file-text',
                url: "/ahpersonalizedrpt",
                status:true,
              },
              {
                id: "ah-detail-rpt",
                title: "Details Report",
                type: "item",
                // icon: 'feather icon-file-text',
                url: "/ahdetailrpt",
              status: rolePermission?.find(item => item.module_id == modules[1].id)?.read,
              },
              {
                id: "ah-parser",
                title: "Resume Parser",
                type: "item",
                // icon: 'feather icon-user-check',
                url: "/resume-parser",
                status:
                  rolePermission?.length > 0
                    ? rolePermission[6]?.create ||
                    rolePermission[6]?.read ||
                    rolePermission[6]?.update ||
                    rolePermission[6]?.delete
                    : false,
              },
              // {
              //     id: 'ah-insights',
              //     title: 'Insights',
              //     type: 'item',
              //     // icon: 'feather icon-user-check',
              //     url: '/insights',
              //     status: rolePermission?.length > 0 ? rolePermission[6]?.create || rolePermission[6]?.read || rolePermission[6]?.update || rolePermission[6]?.delete : false
              // },
              // {
              //   id: "ah-evaluator",
              //   title: "Offline Evaluation",
              //   type: "item",
              //   // icon: 'feather icon-user-check',
              //   url: "/manual-evaluator",
              //   status: true,
              // },
              // {
              //   id: "ah-profile",
              //   title: "My Profile",
              //   type: "item",
              //   // icon: 'feather icon-user-check',
              //   url: "/profile",
              //   onClick: "",
              //   status:
              //     rolePermission?.length > 0
              //       ? rolePermission[7]?.create ||
              //       rolePermission[7]?.read ||
              //       rolePermission[7]?.update ||
              //       rolePermission[7]?.delete
              //       : false,
              // },
            ].filter((i) => i.status === true),
          },
        ],
      }
      : localStorage.getItem("selectedModule") == moduleNames[3]
        ? {
          items: [
            {
              id: "navigation",
              title: "Dashboard",
              type: "group",
              icon: "icon-navigation",
              children: [
                {
                  id: "ah-fb-configure",
                  title: "Configure",
                  type: "item",
                  url: "/fb/configure",
                  status: rolePermission?.find(item => item.module_id == modules[12].id)?.read,
                },
                {
                  id: "ah-fb-assign",
                  title: "Assign",
                  type: "item",
                  url: "/fb/assign",
                  status: rolePermission?.find(item => item.module_id == modules[13].id)?.read,
                },
                // {
                //     id: "ah-fb-manual-assign",
                //     title: "Manual Assign",
                //     type: "item",
                //     url: "/fb/manual-assign",
                // },
                {
                  id: "ah-fb-report",
                  title: "Reports",
                  type: "item",
                  url: "/fb/report",
                  status: rolePermission?.find(item => item.module_id == modules[14].id)?.read,
                },
              ].filter((i) => {
                return i.status === true;
              }),
            },
          ],
        }
        : localStorage.getItem("selectedModule") == moduleNames[4]
          ? {
            items: [
              {
                id: "navigation",
                title: "Dashboard",
                type: "group",
                icon: "icon-navigation",
                children: [
                  // {
                  //   id: "ah-es-create",
                  //   title: "Create",
                  //   type: "item",
                  //   url: "/es/create",
                  //   status:
                  //     rolePermission?.length > 0
                  //       ? rolePermission[19]?.create ||
                  //       rolePermission[19]?.read ||
                  //       rolePermission[19]?.update ||
                  //       rolePermission[19]?.delete
                  //       : false,
                  // },
                  {
                    id: "ah-es-configure",
                    title: "Configure",
                    type: "item",
                    url: "/es/configure",
                    status: rolePermission?.find(item => item.module_id == modules[17].id)?.read,
                  },
                  {
                    id: "ah-es-assign",
                    title: "Assign",
                    type: "item",
                    url: "/es/assign",
                    status: rolePermission?.find(item => item.module_id == modules[18].id)?.read,
                  },
                  {
                    id: "ah-es-report",
                    title: "Reports",
                    type: "item",
                    url: "/es/report",
                    status: rolePermission?.find(item => item.module_id == modules[19].id)?.read,
                  },
                ].filter((i) => {
                  return i.status === true;
                }),
              },
            ],
          }
          : localStorage.getItem("selectedModule") == moduleNames[5]
            ? {
              items: [
                {
                  id: "navigation",
                  title: "Dashboard",
                  type: "group",
                  icon: "icon-navigation",
                  children: [
                    // {
                    //   id: "ah-adc-create",
                    //   title: "Create",
                    //   type: "item",
                    //   // icon: 'feather icon-user-check',
                    //   url: "/adc/create",
                    //   status: rolePermission?.find(item => item.module_id == modules[7].id)?.read,
                    // },
                    {
                      id: "ah-adc-configure",
                      title: "Configure",
                      type: "item",
                      // icon: 'feather icon-user-check',
                      url: "/adc/configure",
                      status: rolePermission?.find(item => item.module_id == modules[8].id)?.read,
                    },
                    {
                      id: "ah-adc-assign",
                      title: "Assign",
                      type: "item",
                      // icon: 'feather icon-file-text',
                      url: "/adc/assign",
                      status: rolePermission?.find(item => item.module_id == modules[9].id)?.read,
                    },
                    {
                      id: "ah-adc-evaluate",
                      title: "Evaluate",
                      type: "item",
                      // icon: 'feather icon-user-check',
                      url: "/adc/evaluate",
                      status: rolePermission?.find(item => item.module_id == modules[10].id)?.read,
                    },
                    {
                      id: "ah-adc-report",
                      title: "Reports",
                      type: "item",
                      // icon: 'feather icon-user-check',
                      url: "/adc/report",
                      status: rolePermission?.find(item => item.module_id == modules[11].id)?.read,
                    },
                  ].filter((i) => {
                    return i.status === true;
                  }),
                },
              ],
            }
            : localStorage.getItem("selectedModule") == moduleNames[6]
              ? {
                items: [
                  {
                    id: "navigation",
                    title: "Dashboard",
                    type: "group",
                    icon: "icon-navigation",
                    children: [
                      {
                        id: "ah-be-project-insights",
                        title: "Project Insights",
                        type: "item",
                        // icon: 'feather icon-users',
                        url: "/be/project-insights",
                        status: rolePermission?.find(item => item.module_id == modules[15].id)?.read,
                      },
                      {
                        id: "ah-be-create-batch",
                        title: "Create",
                        type: "item",
                        // icon: 'feather icon-users',
                        url: "/be/create-batch",
                        status:  rolePermission?.find(item => item.module_id == modules[26].id)?.read,
                      },
                    ],
                  },
                ],
              }
              : localStorage.getItem("selectedModule") == moduleNames[7]
                ? {
                  items: [
                    {
                      id: "navigation",
                      title: "Dashboard",
                      type: "group",
                      icon: "icon-navigation",
                      children: [
                        {
                          id: "ah-bot-login",
                          title: "Bots",
                          type: "item",
                          // icon: 'feather icon-user-check',
                          url: "/bot-api",
                          status:
                            true,
                        },
                      ].filter((i) => {
                        return i.status === true;
                      }),
                    },
                  ],
                }
                : localStorage.getItem("selectedModule") == moduleNames[8]
                ? {
                  items: [
                    {
                      id: "navigation",
                      title: "Dashboard",
                      type: "group",
                      icon: "icon-translate",
                      children: [
                        {
                          id: "ah-translate-login",
                          title: "Translation",
                          type: "item",
                          // icon: 'feather icon-user-check',
                          url: "/translate",
                          status:
                            true,
                        },
                      ].filter((i) => {
                        return i.status === true;
                      }),
                    },
                  ],
                }
                : localStorage.getItem("selectedModule") == moduleNames[9]
                ? {
                  items: [
                    {
                      id: "navigation",
                      title: "Dashboard",
                      type: "group",
                      icon: "icon-faq",
                      children: [
                        {
                          id: "ah-faq",
                          title: "FAQ",
                          type: "item",
                          // icon: 'feather icon-user-check',
                          url: "/faq",
                          status:
                            true,
                        },
                      ].filter((i) => {
                        return i.status === true;
                      }),
                    },
                  ],
                }
                : { items: [] };

if (data.items[0].children.length === 0) {
  data.items[0].children.push({
    id: "ah-no-access",
    title: "No Access",
    type: "item",
    url: "/not-access",
    status: true,
  });
}
export default data;
