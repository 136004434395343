import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import adminroutes from "../routes";
import AdminLayout from './layout/AdminLayout';
const isAuthenticated = !!localStorage.getItem('token');
class App extends Component {

    render() {
        const menu = routes.map((route, index) => {
        return route.component ? (
            <Route
                key={index}
                path={route.path}
                element={<route.component/>}
            />
            ) : null;
        });   
        const adminmenu = adminroutes.map((route, index) => {
            return route.component ? (
                <Route
                    key={index}
                    path={route.path}
                    props={this.props} 
                    element={<route.component />}
                />
                ) : null;
            });    
        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            {menu}
                         
                            {isAuthenticated ? (
                                
                                 <Route path="/" element={ <AdminLayout/>} >
                                    {adminmenu}
                                    <Route index element={<Navigate to="/group" />} />

                                    <Route path="*" element={<div>Catch-all route</div>} />
                                 </Route>
                            ):(
                                <Route path="/" element={  <Navigate to="/auth/signin/" />} />
                            )}
                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
