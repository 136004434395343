const modules = [
    {
        id: 1,
        name: "Positions",
        type: "crud",
    },
    {
        id: 3,
        name: "Test",
        type: "crud",
    },
    {
        id: 4,
        name: "Candidates",
        type: "crud",
    },
    {
        id: 5,
        name: "SkillAreas",
        type: "crud",
    },
    {
        id: 6,
        name: "Questions",
        type: "crud",
    },
    {
        id: 7,
        name: "ResumeParser",
        type: "crud",
    },
    {
        id: 9,
        name: "Insights",
        type: "crud",
    },
    {
        id: 10,
        name: "ADCCreate",
        type: "crud",
    },
    {
        id: 11,
        name: "ADCConfigure",
        type: "crud",
    },
    {
        id: 12,
        name: "ADCAssign",
        type: "crud",
    },
    {
        id: 13,
        name: "ADCEvaluate",
        type: "crud",
    },
    {
        id: 14,
        name: "ADCReports",
        type: "crud",
    },
    {
        id: 15,
        name: "FBConfigure",
        type: "crud",
    },
    {
        id: 16,
        name: "FBAssign",
        type: "crud",
    },
    {
        id: 17,
        name: "FBReports",
        type: "crud",
    },
    {
        id: 18,
        name: "BEDashboard",
        type: "crud",
    },
    {
        id: 19,
        name: "OfflineEvaluation",
        type: "crud",
    },
    {
        id: 20,
        name: "ESCreate",
        type: "crud",
    },
    {
        id: 21,
        name: "ESConfigure",
        type: "crud",
    },
    {
        id: 22,
        name: "ESAssign",
        type: "crud",
    },
    {
        id: 23,
        name: "ESReports",
        type: "crud",
    },
    {
        id: 24,
        name: "DownloadAnswerSheets",
        type: "toggle",
    },
    {
        id: 25,
        name: "DownloadQuestions",
        type: "toggle",
    },
    {
        id: 26,
        name: "EnabledTestTypes",
        type: "list",
    },
    {
        id: 27,
        name: "EnabledQuestionTypes",
        type: "list",
    },
    {
        id: 28,
        name: "EnabledRoles",
        type: "list",
    },
]

const rolePermission = JSON.parse(localStorage.getItem("rolePermission"))

const accessControl = {
    "Positions": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[0].id)?.create || rolePermission?.find(item => item.module_id == modules[0].id)?.read || rolePermission?.find(item => item.module_id == modules[0].id)?.update || rolePermission?.find(item => item.module_id == modules[0].id)?.delete,
        "Create Position": rolePermission?.find(item => item.module_id == modules[0].id)?.create,
        "Copy Position": rolePermission?.find(item => item.module_id == modules[0].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[0].id)?.read,
        "Edit Position": rolePermission?.find(item => item.module_id == modules[0].id)?.update,
        "Delete Position": rolePermission?.find(item => item.module_id == modules[0].id)?.delete,
    },
    "Groups": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[0].id)?.create || rolePermission?.find(item => item.module_id == modules[0].id)?.read || rolePermission?.find(item => item.module_id == modules[0].id)?.update || rolePermission?.find(item => item.module_id == modules[0].id)?.delete,
        "Create Group": rolePermission?.find(item => item.module_id == modules[0].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[0].id)?.read,
        "Edit Group": rolePermission?.find(item => item.module_id == modules[0].id)?.update,
        "Cut Off": rolePermission?.find(item => item.module_id == modules[0].id)?.update,
        "Delete Group": rolePermission?.find(item => item.module_id == modules[0].id)?.delete,
    },
    "Test": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[1].id)?.create || rolePermission?.find(item => item.module_id == modules[1].id)?.read || rolePermission?.find(item => item.module_id == modules[1].id)?.update || rolePermission?.find(item => item.module_id == modules[1].id)?.delete,
        "Create Test": rolePermission?.find(item => item.module_id == modules[1].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[1].id)?.read,
        "Edit Instance": rolePermission?.find(item => item.module_id == modules[1].id)?.update,
        "Edit Test": rolePermission?.find(item => item.module_id == modules[1].id)?.update,
        "Delete Test": rolePermission?.find(item => item.module_id == modules[1].id)?.delete,
    },
    "Candidates": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[2].id)?.create || rolePermission?.find(item => item.module_id == modules[2].id)?.read || rolePermission?.find(item => item.module_id == modules[2].id)?.update || rolePermission?.find(item => item.module_id == modules[2].id)?.delete,
        "Invite Candidates": rolePermission?.find(item => item.module_id == modules[2].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[2].id)?.read,
        "Update Candidates": rolePermission?.find(item => item.module_id == modules[2].id)?.update,
        "Delete Candidates": rolePermission?.find(item => item.module_id == modules[2].id)?.delete,
    },
    "SkillAreas": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[3].id)?.create || rolePermission?.find(item => item.module_id == modules[3].id)?.read || rolePermission?.find(item => item.module_id == modules[3].id)?.update || rolePermission?.find(item => item.module_id == modules[3].id)?.delete,
        "Define Skill Areas": rolePermission?.find(item => item.module_id == modules[3].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[3].id)?.read,
        "Edit Categories": rolePermission?.find(item => item.module_id == modules[3].id)?.update,
        "Delete Category": rolePermission?.find(item => item.module_id == modules[3].id)?.delete,
    },
    "Questions": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[4].id)?.create || rolePermission?.find(item => item.module_id == modules[4].id)?.read || rolePermission?.find(item => item.module_id == modules[4].id)?.update || rolePermission?.find(item => item.module_id == modules[4].id)?.delete,
        "Create Questions": rolePermission?.find(item => item.module_id == modules[4].id)?.create,
        "Listing": rolePermission?.find(item => item.module_id == modules[4].id)?.read,
        "Edit Question": rolePermission?.find(item => item.module_id == modules[4].id)?.update,
        "Delete Question": rolePermission?.find(item => item.module_id == modules[4].id)?.delete,
    },
    "ResumeParser": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[5].id)?.create || rolePermission?.find(item => item.module_id == modules[5].id)?.read || rolePermission?.find(item => item.module_id == modules[5].id)?.update || rolePermission?.find(item => item.module_id == modules[5].id)?.delete,
        "Configure": rolePermission?.find(item => item.module_id == modules[5].id)?.create,
        "Create Profile": rolePermission?.find(item => item.module_id == modules[5].id)?.create,
        "Match Criteria": rolePermission?.find(item => item.module_id == modules[5].id)?.create,
        "View Candidates": rolePermission?.find(item => item.module_id == modules[5].id)?.read,
        "Edit Position": rolePermission?.find(item => item.module_id == modules[5].id)?.update,
    },
    "MyProfile": {
        "moduleAccess": true,
    },
    "Insights": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[6].id)?.create || rolePermission?.find(item => item.module_id == modules[6].id)?.read || rolePermission?.find(item => item.module_id == modules[6].id)?.update || rolePermission?.find(item => item.module_id == modules[6].id)?.delete
    },
    "OfflineEvaluation": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[16].id)?.create || rolePermission?.find(item => item.module_id == modules[16].id)?.read || rolePermission?.find(item => item.module_id == modules[16].id)?.update || rolePermission?.find(item => item.module_id == modules[16].id)?.delete,
        "Create Evaluate": rolePermission?.find(item => item.module_id == modules[16].id)?.create,
        "Read Evalaute": rolePermission?.find(item => item.module_id == modules[16].id)?.read,
        "Update Evaluate": rolePermission?.find(item => item.module_id == modules[16].id)?.update,
        "Delete Evaluate": rolePermission?.find(item => item.module_id == modules[16].id)?.delete,
    },

    "ADCCreate": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[7].id)?.create || rolePermission?.find(item => item.module_id == modules[7].id)?.read || rolePermission?.find(item => item.module_id == modules[7].id)?.update || rolePermission?.find(item => item.module_id == modules[7].id)?.delete,
        "Create Create": rolePermission?.find(item => item.module_id == modules[7].id)?.create,
        "Read Create": rolePermission?.find(item => item.module_id == modules[7].id)?.read,
        "Update Create": rolePermission?.find(item => item.module_id == modules[7].id)?.update,
        "Delete Create": rolePermission?.find(item => item.module_id == modules[7].id)?.delete,
    },
    "ADCConfigure": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[8].id)?.create || rolePermission?.find(item => item.module_id == modules[8].id)?.read || rolePermission?.find(item => item.module_id == modules[8].id)?.update || rolePermission?.find(item => item.module_id == modules[8].id)?.delete,
        "Create Configure": rolePermission?.find(item => item.module_id == modules[8].id)?.create,
        "Read Configure": rolePermission?.find(item => item.module_id == modules[8].id)?.read,
        "Update Configure": rolePermission?.find(item => item.module_id == modules[8].id)?.update,
        "Delete Configure": rolePermission?.find(item => item.module_id == modules[8].id)?.delete,
    },
    "ADCAssign": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[9].id)?.create || rolePermission?.find(item => item.module_id == modules[9].id)?.read || rolePermission?.find(item => item.module_id == modules[9].id)?.update || rolePermission?.find(item => item.module_id == modules[9].id)?.delete,
        "Create Assign": rolePermission?.find(item => item.module_id == modules[9].id)?.create,
        "Read Assign": rolePermission?.find(item => item.module_id == modules[9].id)?.read,
        "Update Assign": rolePermission?.find(item => item.module_id == modules[9].id)?.update,
        "Delete Assign": rolePermission?.find(item => item.module_id == modules[9].id)?.delete,
    },
    "ADCEvaluate": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[10].id)?.create || rolePermission?.find(item => item.module_id == modules[10].id)?.read || rolePermission?.find(item => item.module_id == modules[10].id)?.update || rolePermission?.find(item => item.module_id == modules[10].id)?.delete,
        "Create Evaluate": rolePermission?.find(item => item.module_id == modules[10].id)?.create,
        "Read Evaluate": rolePermission?.find(item => item.module_id == modules[10].id)?.read,
        "Update Evaluate": rolePermission?.find(item => item.module_id == modules[10].id)?.update,
        "Delete Evaluate": rolePermission?.find(item => item.module_id == modules[10].id)?.delete,
    },
    "ADCReports": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[11].id)?.create || rolePermission?.find(item => item.module_id == modules[11].id)?.read || rolePermission?.find(item => item.module_id == modules[11].id)?.update || rolePermission?.find(item => item.module_id == modules[11].id)?.delete,
        "Create Reports": rolePermission?.find(item => item.module_id == modules[11].id)?.create,
        "Read Reports": rolePermission?.find(item => item.module_id == modules[11].id)?.read,
        "Update Reports": rolePermission?.find(item => item.module_id == modules[11].id)?.update,
        "Delete Reports": rolePermission?.find(item => item.module_id == modules[11].id)?.delete,
    },

    "FBConfigure": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[12].id)?.create || rolePermission?.find(item => item.module_id == modules[12].id)?.read || rolePermission?.find(item => item.module_id == modules[12].id)?.update || rolePermission?.find(item => item.module_id == modules[12].id)?.delete,
        "Create Configure": rolePermission?.find(item => item.module_id == modules[12].id)?.create,
        "Read Configure": rolePermission?.find(item => item.module_id == modules[12].id)?.read,
        "Update Configure": rolePermission?.find(item => item.module_id == modules[12].id)?.update,
        "Delete Configure": rolePermission?.find(item => item.module_id == modules[12].id)?.delete,
    },
    "FBAssign": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[13].id)?.create || rolePermission?.find(item => item.module_id == modules[13].id)?.read || rolePermission?.find(item => item.module_id == modules[13].id)?.update || rolePermission?.find(item => item.module_id == modules[13].id)?.delete,
        "Create Assign": rolePermission?.find(item => item.module_id == modules[13].id)?.create,
        "Read Assign": rolePermission?.find(item => item.module_id == modules[13].id)?.read,
        "Update Assign": rolePermission?.find(item => item.module_id == modules[13].id)?.update,
        "Delete Assign": rolePermission?.find(item => item.module_id == modules[13].id)?.delete,
    },
    "FBReports": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[14].id)?.create || rolePermission?.find(item => item.module_id == modules[14].id)?.read || rolePermission?.find(item => item.module_id == modules[14].id)?.update || rolePermission?.find(item => item.module_id == modules[14].id)?.delete,
        "Create Reports": rolePermission?.find(item => item.module_id == modules[14].id)?.create,
        "Read Reports": rolePermission?.find(item => item.module_id == modules[14].id)?.read,
        "Update Reports": rolePermission?.find(item => item.module_id == modules[14].id)?.update,
        "Delete Reports": rolePermission?.find(item => item.module_id == modules[14].id)?.delete,
    },

    "BEDashboard": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[15].id)?.create || rolePermission?.find(item => item.module_id == modules[15].id)?.read || rolePermission?.find(item => item.module_id == modules[15].id)?.update || rolePermission?.find(item => item.module_id == modules[15].id)?.delete,
    },
    
    "ESCreate": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[17].id)?.create || rolePermission?.find(item => item.module_id == modules[17].id)?.read || rolePermission?.find(item => item.module_id == modules[17].id)?.update || rolePermission?.find(item => item.module_id == modules[17].id)?.delete,
        "Create Create": rolePermission?.find(item => item.module_id == modules[17].id)?.create,
        "Read Create": rolePermission?.find(item => item.module_id == modules[17].id)?.read,
        "Update Create": rolePermission?.find(item => item.module_id == modules[17].id)?.update,
        "Delete Create": rolePermission?.find(item => item.module_id == modules[17].id)?.delete,
    },
    "ESConfigure": {
        "moduleAccess": rolePermission?.find(item => item.module_id == modules[18].id)?.create || rolePermission?.find(item => item.module_id == modules[18].id)?.read || rolePermission?.find(item => item.module_id == modules[18].id)?.update || rolePermission?.find(item => item.module_id == modules[18].id)?.delete,
        "Create Configure": rolePermission?.find(item => item.module_id == modules[18].id)?.create,
        "Read Configure": rolePermission?.find(item => item.module_id == modules[18].id)?.read,
        "Update Configure": rolePermission?.find(item => item.module_id == modules[18].id)?.update,
        "Delete Configure": rolePermission?.find(item => item.module_id == modules[18].id)?.delete,
    },
    "ESAssign": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[19].id)?.create || rolePermission?.find(item => item.module_id == modules[19].id)?.read || rolePermission?.find(item => item.module_id == modules[19].id)?.update || rolePermission?.find(item => item.module_id == modules[19].id)?.delete,
        "Create Assign": rolePermission?.find(item => item.module_id == modules[19].id)?.create,
        "Read Assign": rolePermission?.find(item => item.module_id == modules[19].id)?.read,
        "Update Assign": rolePermission?.find(item => item.module_id == modules[19].id)?.update,
        "Delete Assign": rolePermission?.find(item => item.module_id == modules[19].id)?.delete,
    },
    "ESReports": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[20].id)?.create || rolePermission?.find(item => item.module_id == modules[20].id)?.read || rolePermission?.find(item => item.module_id == modules[20].id)?.update || rolePermission?.find(item => item.module_id == modules[20].id)?.delete,
        "Create Reports": rolePermission?.find(item => item.module_id == modules[20].id)?.create,
        "Read Reports": rolePermission?.find(item => item.module_id == modules[20].id)?.read,
        "Update Reports": rolePermission?.find(item => item.module_id == modules[20].id)?.update,
        "Delete Reports": rolePermission?.find(item => item.module_id == modules[20].id)?.delete,
    },
    "DownloadAnswerSheets": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[21].id)?.create || rolePermission?.find(item => item.module_id == modules[21].id)?.read || rolePermission?.find(item => item.module_id == modules[21].id)?.update || rolePermission?.find(item => item.module_id == modules[21].id)?.delete,
        "create": rolePermission?.find(item => item.module_id == modules[21].id)?.create,
        "read": rolePermission?.find(item => item.module_id == modules[21].id)?.read,
        "update": rolePermission?.find(item => item.module_id == modules[21].id)?.update,
        "delete": rolePermission?.find(item => item.module_id == modules[21].id)?.delete,
    },
    "DownloadQuestions": {
        "moduleAccess":  rolePermission?.find(item => item.module_id == modules[22].id)?.create || rolePermission?.find(item => item.module_id == modules[22].id)?.read || rolePermission?.find(item => item.module_id == modules[22].id)?.update || rolePermission?.find(item => item.module_id == modules[22].id)?.delete,
        "create": rolePermission?.find(item => item.module_id == modules[22].id)?.create,
        "read": rolePermission?.find(item => item.module_id == modules[22].id)?.read,
        "update": rolePermission?.find(item => item.module_id == modules[22].id)?.update,
        "delete": rolePermission?.find(item => item.module_id == modules[22].id)?.delete,
    },
    "EnabledTestTypes": {
        "enabled":  rolePermission?.find(item => item.module_id == modules[23].id)?.enabled,
    },
    "EnabledQuestionTypes": {
        "enabled":  rolePermission?.find(item => item.module_id == modules[24].id)?.enabled,
    },
    "EnabledRoles": {
        "enabled":  rolePermission?.find(item => item.module_id == modules[25].id)?.enabled,
    },
}

module.exports = accessControl;