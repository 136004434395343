import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
//import { withRouter } from 'react-router-dom';
//import windowSize from 'react-window-size';

import Aux from "../../../../../../hoc/_Aux";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import * as actionTypes from "../../../../../../store/actions";
import { useLocation } from 'react-router-dom';


//class NavItem extends Component {
const NavItem = () => {
    const uselocation = useLocation();

    return () => {

        let itemTitle = this.props.item.title;

        if (this.props.item.icon) {
            itemTitle = <span className="pcoded-mtext">{this.props.item.title}</span>;
        }

        let itemTarget = '';
        if (this.props.item.target) {
            itemTarget = '_blank';
        }

        let subContent;

        if (this.props.item.external) {
            subContent = (
                <a href={this.props.item.url} target='_blank' rel='noopener noreferrer'>
                    <NavIcon items={this.props.item} />
                    {itemTitle}
                    <NavBadge layout={this.props.layout} items={this.props.item} />
                </a>
            );
        } else {

            subContent = (
                <NavLink to={this.props.item.url} className={uselocation.pathname.indexOf(this.props.item.url) ? "nav-link nav-links " : `nav-link activenav activenav-${this.props.theme}`} exact={true} target={itemTarget}>
                    <NavIcon items={this.props.item} />
                    {itemTitle}

                    <NavBadge layout={this.props.layout} items={this.props.item} />
                </NavLink>
            );
        }
        let mainContent = '';
        if (this.props.layout === 'horizontal') {
            mainContent = (
                <li bg="success" onClick={this.props.onItemLeave}>{subContent}</li>
            );
        } else {
            if (window.innerWidth < 992) {
                mainContent = (
                    <li className={this.props.item.classes} onClick={this.props.onItemClick}>{subContent}</li>
                );
            } else {
                mainContent = (
                    <li className={this.props.item.classes}>{subContent}</li>
                );
            }
        }

        return (
            <Aux>
                {mainContent}
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        layout: state.layout,
        collapseMenu: state.collapseMenu
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onItemClick: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
        onItemLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE })
    }
};



//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(NavItem)));

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
