import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Aux from "../../../../../../hoc/_Aux";
import * as actionTypes from "../../../../../../store/actions";
import DEMO from "../../../../../../store/constant";
import { changeTheme } from '../../../../../../service/profileApiService'

const ColorOptions = (props) => {
    let colorOptions = '';
    colorOptions = (
        <div>
            <h6 className=" text-dark">Background Color</h6>
            <div className="theme-color background-color flat">
                <a onClick={() => props.onChangeHeaderBackColor('primary')} className={props.headerBackColor === 'background-blue' ? 'active' : ''} data-value="background-blue"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('danger')} className={props.headerBackColor === 'background-red' ? 'active' : ''} data-value="background-red"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('secondary')} className={props.headerBackColor === 'background-purple' ? 'active' : ''} data-value="background-purple"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('info')} className={props.headerBackColor === 'background-info' ? 'active' : ''} data-value="background-info"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('success')} className={props.headerBackColor === 'background-green' ? 'active' : ''} data-value="background-green"><span /><span /></a>
                {/* <a onClick={() => props.onChangeHeaderBackColor('warning')}  className={props.headerBackColor === 'background-warning' ? 'active' : ''} data-value="background-warning"><span style={{background:"#f7ba56"}}  /><span /></a> */}
            </div>
            {/* <h6 className=" text-dark">Background Color</h6>
            <div className="theme-color background-color flat">
                <a onClick={() => props.onChangeHeaderBackColor('background-blue')} className={props.headerBackColor === 'background-blue' ? 'active' : ''} data-value="background-blue"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-red')} className={props.headerBackColor === 'background-red' ? 'active' : ''} data-value="background-red"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-purple')} className={props.headerBackColor === 'background-purple' ? 'active' : ''} data-value="background-purple"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-info')} className={props.headerBackColor === 'background-info' ? 'active' : ''} data-value="background-info"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-green')} className={props.headerBackColor === 'background-green' ? 'active' : ''} data-value="background-green"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-dark')} className={props.headerBackColor === 'background-dark' ? 'active' : ''} data-value="background-dark"><span /><span /></a>
            </div>
            <h6 className=" text-dark">Background Gradient</h6>
            <div className="theme-color background-color gradient">
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-blue')} className={props.headerBackColor === 'background-grd-blue' ? 'active' : ''} data-value="background-grd-blue"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-red')} className={props.headerBackColor === 'background-grd-red' ? 'active' : ''} data-value="background-grd-red"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-purple')} className={props.headerBackColor === 'background-grd-purple' ? 'active' : ''} data-value="background-grd-purple"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-info')} className={props.headerBackColor === 'background-grd-info' ? 'active' : ''} data-value="background-grd-info"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-green')} className={props.headerBackColor === 'background-grd-green' ? 'active' : ''} data-value="background-grd-green"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-grd-dark')} className={props.headerBackColor === 'background-grd-dark' ? 'active' : ''} data-value="background-grd-dark"><span /><span /></a>
            </div>
            <h6 className=" text-dark">Background Image</h6>
            <div className="theme-color background-color image">
                <a onClick={() => props.onChangeHeaderBackColor('background-img-1')} className={props.headerBackColor === 'background-img-1' ? 'active' : ''} data-value="background-img-1"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-img-2')} className={props.headerBackColor === 'background-img-2' ? 'active' : ''} data-value="background-img-2"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-img-3')} className={props.headerBackColor === 'background-img-3' ? 'active' : ''} data-value="background-img-3"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-img-4')} className={props.headerBackColor === 'background-img-4' ? 'active' : ''} data-value="background-img-4"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-img-5')} className={props.headerBackColor === 'background-img-5' ? 'active' : ''} data-value="background-img-5"><span /><span /></a>
                <a onClick={() => props.onChangeHeaderBackColor('background-img-6')} className={props.headerBackColor === 'background-img-6' ? 'active' : ''} data-value="background-img-6"><span /><span /></a>
            </div> */}
        </div>
    );

    return (
        <Aux>
            <div className="config-scroll">
                <PerfectScrollbar>
                    {colorOptions}
                </PerfectScrollbar>
            </div>
        </Aux>
    );
}
const mapStateToProps = state => {
    return {
        layout: state.layout,
        headerBackColor: state.headerBackColor
    }
};

const mapDispatchToProps = dispatch => {
    return {

        onChangeHeaderBackColor: (headerBackColor) => {
            dispatch({ type: actionTypes.HEADER_BACK_COLOR, headerBackColor: headerBackColor })
            changeTheme({
                field_name: "THEME",
                field_details: headerBackColor,
                order_no: "0",
                created_on: Date.now(),
                // created_by: userId,
            });
            window.location.reload()
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorOptions);