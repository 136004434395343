import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import windowSize from 'react-window-size';
import { logout } from '../../../../service/dataService'
import NavLeft from "./NavLeft";
import NavRight from "./NavRight";
import Aux from "../../../../hoc/_Aux";
import DEMO from "../../../../store/constant";
import * as actionTypes from "../../../../store/actions";
import { withRouter } from 'react-router-dom';
import constant from '../../../../service/constants';
import { Navbar, Nav, Container } from 'react-bootstrap';
import NavContent from '../Navigation/NavContent';
import OutsideClick from '../Navigation/OutsideClick';
import navigation from '../../../../menu-items';
import navigationOrg from '../../../../menu-item-org';
import logo from '../../../../assets/images/assesshub logo green dot.svg';
import { getTheme } from '../../../../service/profileApiService'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const NavBar = (props) => {
    const [rightToggle, setRightToggle] = useState()
    const [theme, setTheme] = useState('primary')
    const [mainLogo, setMainLogo] = useState(logo)
    const [isLoading, setIsLoading] = useState(true)
    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', 'header-blue'];
    document.body.classList.remove('background-blue');
    document.body.classList.remove('background-red');
    document.body.classList.remove('background-purple');
    document.body.classList.remove('background-info');
    document.body.classList.remove('background-green');
    document.body.classList.remove('background-dark');
    document.body.classList.remove('background-grd-blue');
    document.body.classList.remove('background-grd-red');
    document.body.classList.remove('background-grd-purple');
    document.body.classList.remove('background-grd-info');
    document.body.classList.remove('background-grd-green');
    document.body.classList.remove('background-grd-dark');
    document.body.classList.remove('background-img-1');
    document.body.classList.remove('background-img-2');
    document.body.classList.remove('background-img-3');
    document.body.classList.remove('background-img-4');
    document.body.classList.remove('background-img-5');
    document.body.classList.remove('background-img-6');
    document.body.classList.add(props.headerBackColor);

    if (props.headerFixedLayout) {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }
    // const color = getComputedStyle(document.documentElement).getPropertyValue('--my-theme');

    // document.documentElement.style.setProperty('--my-theme', "success");

    let toggleClass = ['mobile-menu'];
    if (props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }
    // let mainLogo = constant.API_URL + '';
    let navHtml;


    // const themecolor = async () => {
    //     await getTheme().then((theme) => {
    //         return theme
    //     })
    // };

    // console.log("==> themecolor", themecolor())
    if (!rightToggle && window.innerWidth < 992) {
        navHtml = ''
    } else {
        navHtml = (
            <div style={{ background: "#246586" }} className="collapse navbar-collapse d-flex">
                <NavLeft />
                <NavRight rtlLayout={props.rtlLayout} />
            </div>
        );
    }
    let navBar = (
        <Aux>
            <div style={{ background: "#f2f2f2" }} className="m-header">
                <a className={toggleClass.join(' ')} id="mobile-collapse1" onClick={props.onToggleNavigation}><span /></a>
                <a href={DEMO.BLANK_LINK} className="b-brand">
                    <img id="main-logo" src={mainLogo} alt="" className="img-fluid" />
                </a>
                <a className="mob-toggler" href={DEMO.BLANK_LINK} onClick={() => setRightToggle(!rightToggle)}><i className="feather icon-more-vertical" /></a>
            </div>
            {navHtml}
        </Aux>
    );
    if (props.layout === 'horizontal' && props.subLayout === 'horizontal-2') {
        navBar = (
            <div className="container">
                {navBar}
            </div>
        );
    }
    useEffect(() => {
        getTheme().then((res) => {
            let themes = res?.result?.find((i) => i.field_name == 'THEME')?.field_details
            let newlogo = constant.API_URL + '/profile/' + res?.result?.find((i) => i.field_name == 'LOGO')?.field_details
            if (themes) {
                setTheme(themes)
            }
            setIsLoading(false)
            // if (res?.result?.find((i) => i.field_name == 'LOGO')?.field_details) {
            // setMainLogo(newlogo)
            // }

        })
    }, [])
    return (
        <Aux>
            {isLoading ?
                <Skeleton height="80px" />
                :
                <Navbar fixed='top' bg={theme} expand="lg" style={{ padding: "16px", minHeight: "60px" }} >
                    <Navbar.Brand className='d-inline d-lg-none' bg='light' style={{ background: "transparent" }} href="">
                        {/* <img id="main-logo" src={mainLogo} alt="" style={{ width: "150px", height: "auto" }} className="img img-responsive" /> */}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse bg="success" id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavContent theme={theme} navigation={localStorage.getItem("orgId") === "1" && localStorage.getItem("roleId") === "1" ? navigationOrg.items : navigation.items} />
                            {/* <Nav.Link style={{ float: 'right' }} onClick={logout} ><i className=""></i>Logout</Nav.Link> */}
                        </Nav>
                        <Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            }
        </Aux>
    );
}

const mapStateToProps = state => {
    return {
        // rtlLayout: rtlLayout,
        // headerBackColor: headerBackColor,
        // headerFixedLayout: headerFixedLayout,
        // collapseMenu: collapseMenu,
        // layout: layout,
        // subLayout: subLayout
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onToggleNavigation: () => dispatch({ type: actionTypes.COLLAPSE_MENU }),
    }
};
//export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavBar));
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
