import React from "react";

export const Footer = () => {
    return (
        <div>
            <hr className="m-0" />
            <div style={{ padding: "40px", backgroundColor: "#F5F5F5", textAlign: "center" }}>
                &#169; Powered by Five Elements Business Solutions Pvt. Ltd. {new Date().getFullYear()}
            </div>
        </div>
    )
};

export default Footer;