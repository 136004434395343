import React, { Component } from 'react';
import {Dropdown} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Aux from "../../../../../hoc/_Aux";
import DEMO from "../../../../../store/constant";

import Avatar1 from '../../../../../assets/images/user/avatar-1.jpg';
import Avatar2 from '../../../../../assets/images/user/avatar-2.jpg';

import {logout} from '../../../../../service/dataService'
class NavRight extends Component {
    state = {
        listOpen: false
    };

    render() {

        return (
            <Aux>
                <ul className="navbar-nav ml-auto">
                    <li>
                        <a onClick={logout}><span>Logout</span></a>
                        {/* <Dropdown alignRight={!this.props.rtlLayout} className="drp-user">
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                                <i className="icon feather icon-user"/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu alignRight className="profile-notification">
                                <div className="pro-head" style={{background:'#fff',color:'#4680ff'}}>
                                    <img src={Avatar1} className="img-radius" alt="User Profile"/>
                                    <span>{JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user")).name}</span>
                                    <a className="dud-logout" title="Logout" style={{color:'#4680ff'}}>
                                        <i className="feather icon-log-out"/>
                                    </a>
                                    
                                </div>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </li>
                </ul>
            </Aux>
        );
    }
}

export default NavRight;
