import moduleNames from "./App/layout/AdminLayout/SideBar/moduleNames";

export default localStorage.getItem("selectedModule") == moduleNames[0] || localStorage.getItem("selectedModule") == moduleNames[1] ||
    (localStorage.getItem("selectedModule") != moduleNames[0] &&
        localStorage.getItem("selectedModule") != moduleNames[1])
    ? {
        items: [
            {
                id: "navigation",
                title: "Dashboard",
                type: "group",
                icon: "icon-navigation",
                children: [
                    {
                        id: "ah-s-admin-dashboard",
                        title: "Dashboard",
                        type: "item",
                        // icon: 'feather icon-user-check',
                        url: "/s-admin/dashboard",
                    },
                    {
                        id: 'ah-position',
                        title: 'My Organisations',
                        type: 'item',
                        // icon: 'feather icon-user-check',
                        url: '/organisation'
                    },
                    {
                        id: 'ah-group',
                        title: 'Billing & Collection',
                        type: 'item',
                        // icon: 'feather icon-user-check',
                        url: '/org-billing'
                    }, {
                        id: 'ah-group',
                        title: 'RBAC',
                        type: 'item',
                        // icon: 'feather icon-user-check',
                        url: '/rbac'
                    }
                ],
            },
        ],
    // }
    // : localStorage.getItem("selectedModule") == moduleNames[1]
    //     ? {
    //         items: [
    //             {
    //                 id: 'navigation',
    //                 title: 'Dashboard',
    //                 type: 'group',
    //                 icon: 'icon-navigation',
    //                 children: [
    //                     {
    //                         id: 'ah-position',
    //                         title: 'My Organisations',
    //                         type: 'item',
    //                         // icon: 'feather icon-user-check',
    //                         url: '/organisation'
    //                     },
    //                     {
    //                         id: 'ah-group',
    //                         title: 'Billing & Collection',
    //                         type: 'item',
    //                         // icon: 'feather icon-user-check',
    //                         url: '/org-billing'
    //                     }, {
    //                         id: 'ah-group',
    //                         title: 'RBAC',
    //                         type: 'item',
    //                         // icon: 'feather icon-user-check',
    //                         url: '/rbac'
    //                     }

    //                 ]
    //             },
    //         ]
        } : { items: [] };